<template>
    <div class="text-wrapper" :class="classes" v-if="dates.start && dates.end">
        <span class="text-gray">&nbsp;Старт&nbsp;</span>
        <span>{{ reversedDate(dates.start) }} -</span>
        <span class="text-gray">&nbsp;Окончание&nbsp;</span>
        <span>{{ reversedDate(dates.end) }}</span>
    </div>
</template>

<script>
export default {
    props: {
        dates: Object,
        classes: String,
    },

    setup() {
        const reversedDate = date => {
            return date && date.split('-').reverse().join('.')
        }

        return { reversedDate }
    },
}
</script>
