<template>
    <div class="courses-list">
        <div class="card-border card-border--course" v-for="course in courses" :key="course.id">

            <router-link :to="{ name: 'courses-item', params: { slug: course.slug }, hash: '#books' }" class="card-border__link"></router-link>

            <div class="card-border__content">
                <div class="card-border__subtitle text-gray">{{ course.category + '. ' + course.type }}</div>
                <div class="card-border__title subtitle text-blue">Курс {{ course.name }}</div>
                <Period :dates="course.dates" />
            </div>
            <button class="btn btn-tr">
                <svg class="icon icon-arrow-right ">
                    <use xlink:href="#arrow-right"></use>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import store from "@/store/index.js"
import Period from "@/pages/personal/components/period"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_GET_COURSES").then(() => {
            next();
        });
    },

    components: {
        Period
    },

    computed: {
        courses() {
            return this.$store.state.student.courses
        }
    }
}
</script>
